<template>
  <v-card tile elevation="1" class="fill-height" style="border-left: 3px solid #5bb55f" min-height="280" >
    <MainModal
        :main="{ component: 'AbsentPersons', title: 'Відсутні мешканці' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_absent_persons)"
        :modal="show_absent_persons_dialog"
        @updateItemModal="absentPersonsUpdateItemModal"
    />
    <v-card-title class="pa-0">
      <v-toolbar elevation="0" class="pa-0">
        <v-toolbar-title class="toolbar-work-place-title">
          Перелік відсутніх мешканців
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom color="success">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="openAbsentPersonsCreateDialog"
                   v-bind="attrs"
                   v-on="on"
                   class="grey lighten-4 mr-1">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Створити відсутніх мешканців</span>
        </v-tooltip>
      </v-toolbar>
    </v-card-title>
    <v-data-table
        :headers="houseParameterHeaders"
        :items="items"
        hide-default-footer
        class="custom-table custom-table-1"
        @click:row="onAbsentPersonsItemClick"
    >
      <template v-slot:item.icon>
        <v-icon size="26" class="mr-2" color="success">
          mdi-format-list-numbered
        </v-icon>
      </template>
      <template v-slot:item.absent_reason="{ item }">
        <span>
          {{ getAbsentReason(item.absent_reason) }}
        </span>
      </template>
      <template v-slot:item.date_start="{ item }">
        <span>
          {{ item.date_start | formatDate }}
        </span>
      </template>
      <template v-slot:item.date_end="{ item }">
        <span>
          {{ item.date_end | formatDate }}
        </span>
      </template>
      <template v-slot:item.value_dec="{ item }">
        <span>
          {{ item.persons | formatNumber }}
        </span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import MainModal from "@/components/modal/MainModal";
import {mapActions, mapGetters} from "vuex";
import {
  GET_ALL_ABSENT_PERSONS,
} from "@/store/actions/absent";
import {getAbsentReason} from "@/utils/icons"
export default {
  name: "HWP_AbsentPersons",
  props: {
    inner_person_hash: {
      type: String,
      default() {
        return ''
      }
    }
  },
  components: { MainModal },
  data() {
    return {
      flat_indicator_dialog_id: 0,
      houseParameterHeaders: [
        { text: '', value: 'icon', width: 26 },
        { text: 'Причина відсутності', value: 'absent_reason' },
        { text: 'К-сть відсутніх', value: 'persons' },
        { text: 'Початок', value: 'date_start' },
        { text: 'Закінчення', value: 'date_end' },
      ],
      selected_absent_persons: {},
      show_absent_persons_dialog: false
    }
  },
  computed: {
    ...mapGetters({
      items: 'getAbsentPersons',
      flat: 'getFlat'
    })
  },
  methods: {
    ...mapActions({
      fetchItems: GET_ALL_ABSENT_PERSONS,
    }),
    getAbsentReason,
    absentPersonsUpdateItemModal(payload) {
      this.show_absent_persons_dialog = false
      this.selected_absent_persons = {}

      if (payload) {
        if (payload.payload === 'fetch') {
          this.$emit('fetch')
        }
      }
    },
    openAbsentPersonsCreateDialog() {
      this.selected_absent_persons = {
        flat_name: this.flat.full_address,
        person_hash: this.flat.person_hash
      }
      this.show_absent_persons_dialog = true
    },
    onAbsentPersonsItemClick(payload) {
      const local_payload = JSON.parse(JSON.stringify(payload))
      local_payload.flat_name = this.flat.full_address
      local_payload.person_hash = this.flat.person_hash
      this.selected_absent_persons = local_payload
      this.show_absent_persons_dialog = true
    },
  },
  created() {
    const person_hash = this.$route.params.person_hash ? this.$route.params.person_hash : this.inner_person_hash
    if (person_hash) {
      this.fetchItems(person_hash)
    }
  }
}
</script>

<style scoped lang="scss">

</style>